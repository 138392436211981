import React from "react"
import { Bar } from 'react-chartjs-2';


import Layout from "../components/layout"
import SEO from "../components/seo"

export default class IndexPage extends React.Component {

    render() {
        const data = {
            labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
            datasets: [
              {
                
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
              },
            ],
          };          
          const options = {
            type: "horizontalBar",
            indexAxis: 'y',
            plugins: {
                legend: {
                    display: false,
                    labels: {
                        color: 'rgb(255, 99, 132)'
                    }
                }
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          };

       


        return(
            <Layout>
                <SEO title="Výsledku"/>
                <div className="flex items-center min-h-screen bg-gray-50 dark:bg-gray-900">
                <div className="container mx-auto">
                <div className="items-center justify-center" >
                    <div className="bg-white border py-2 px-5 rounded-lg flex items-center flex-col">
                    
                    <div className="text-gray-600 text-2xl font-black mt-2 text-center">
                    Do akej miery boli prednášky na tomto online podujatí pre Vás užitočné?
                        <Bar data={data} options={options} type='horizontalBar' className="container" />
                    </div>
                    </div>
                </div></div></div>
            </Layout>
        );
    }
}